/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { Input } from "reactstrap";
import InputLabelField from "../../../components/inputLabelField";
import useEditRestricted from "../../../hooks/restrictions/useEditRestricted";
import { ModuleName } from "../../../utils/constants/Constants";

interface ILabelComponent {
	setFieldValue: any;
	values: any;
	setSaveBtnDisabled: any;
	valueKey: any;
}

const LabelComponent: FC<ILabelComponent> = ({ setFieldValue, values, setSaveBtnDisabled, valueKey }) => {
	const { editRestrictedPermission } = useEditRestricted();
	return (
		<div className="col-12 test mt-3">
			<div className="row">
				<div className="col-3">
					<Input type="text" disabled name={`${values.language}`} value={valueKey} />
				</div>
				<InputLabelField
					setFieldValue={setFieldValue}
					// @ts-ignore
					value={values.EN[`${valueKey}`]}
					keyValue={valueKey}
					lang="EN"
					disable={editRestrictedPermission(ModuleName.LABEL_MANAGEMENT)}
					setSaveBtnDisabled={setSaveBtnDisabled}
				/>
				<InputLabelField
					setFieldValue={setFieldValue}
					// @ts-ignore
					value={values.DE[`${valueKey}`]}
					keyValue={valueKey}
					lang="DE"
					disable={editRestrictedPermission(ModuleName.LABEL_MANAGEMENT)}
					setSaveBtnDisabled={setSaveBtnDisabled}
				/>
			</div>
		</div>
	);
};

export default LabelComponent;
